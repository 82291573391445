import React, { useState, useEffect } from 'react';
import { Button, Modal, Spinner } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import CustomModalHeader from './CustomModalHeader';
import styles from './UpgradeOrgSubscriptionPopup.module.css';
import { post_usage } from '../utils/usage';
import { Auth } from 'aws-amplify';
import { fetchFromApi } from '../utils/api';

interface UpgradeOrgSubscriptionPopupProps {
    org_name: string;
    org_email: string;
    user_email: string;
}

const UpgradeOrgSubscriptionPopup: React.FC<UpgradeOrgSubscriptionPopupProps> = ({ org_name, org_email, user_email }) => {
    const [show, setShow] = useState(false);
    const location = useLocation();
    const [isLeaving, setIsLeaving] = useState(false);
    const [isResubscribing, setIsResubscribing] = useState(false);

    useEffect(() => {
        const excludedPaths = ['/support', '/account'];
        if (!excludedPaths.includes(location.pathname)) {
            setShow(true);
            post_usage(`upgrade_org_popup`);
        }
    }, [location.pathname]);

    // Placeholder methods for button actions
    const handleReSubscribe = async () => {
        setIsResubscribing(true);
        post_usage(`upgrade_org_popup_resubscribe`);
        
        try {
            const response = await fetchFromApi('/resubscribe_org', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email: user_email }),
            });

            if (response.ok) {
                window.location.href = `https://juhakiili.gumroad.com/l/csgolens-org?wanted=true&email=${user_email}`;
            } else {
                console.error('Failed to resubscribe');
                setIsResubscribing(false);
            }
        } catch (error) {
            console.error('Error resubscribing:', error);
            setIsResubscribing(false);
        }
    };

    const handleLeaveOrganization = async () => {
        setIsLeaving(true);
        post_usage(`upgrade_org_popup_leave_org`);
        
        try {
            const response = await fetchFromApi('/remove_member', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email: user_email }),
            });

            if (response.ok) {
                window.location.reload();
            } else {
                console.error('Failed to leave organization');
                setIsLeaving(false);
            }
        } catch (error) {
            console.error('Error leaving organization:', error);
            setIsLeaving(false);
        }
    };

    const handleLogout = async (event: React.FormEvent) => {
        try {
            post_usage(`upgrade_org_popup_logout`);
            await Auth.signOut();
            window.location.href = "/";
        } catch (error) {
            console.log('Error signing out: ', error);
        }
    }

    return (
        <Modal show={show} dialogClassName={styles.modal}>
            <CustomModalHeader hideCloseButton={true} />
            <div style={{ margin: '25px' }} className={styles.content}>
                <p>Hello there! 👋</p>
                <p>Your account <code>{user_email}</code> is part of the organization <code>{org_name}</code>.</p>
                <p>Unfortunately, the subscription for this organization has either been cancelled or the payments are failing. To continue normal operations, your organization is required to re-subscribe.</p>
                <p>If you already re-subscribed, please wait a moment and refresh the page.</p>
                <p>If you have any questions, please <a href="/support">contact</a> us immediately.</p>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', margin: '0 25px 25px' }}>
                <Button variant="success" onClick={handleReSubscribe} style={{ marginRight: "auto" }} disabled={isResubscribing}>
                    {isResubscribing ? (
                        <Spinner animation="border" size="sm" style={{ marginRight: 10 }} />
                    ) : (
                        <i className="bi bi-arrow-clockwise" style={{ marginRight: 10 }} />
                    )}
                    Re-subscribe
                </Button>
                <div style={{ display: 'flex', gap: 10 }}>
                    <Button variant="outline-danger" onClick={handleLeaveOrganization} disabled={isLeaving}>
                        {isLeaving ? (
                            <Spinner animation="border" size="sm" style={{ marginRight: 10 }} />
                        ) : (
                            <i className="bi bi-box-arrow-left" style={{ marginRight: 10 }} />
                        )}
                        Leave Organization
                    </Button>
                    <Button variant="outline-primary" onClick={handleLogout}>
                        <i className="bi bi-door-closed" style={{ marginRight: 10 }} />Logout
                    </Button>
                </div>
            </div>
        </Modal>
    );
}

export default UpgradeOrgSubscriptionPopup;
