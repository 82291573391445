import React, { useRef, useState } from "react";
import styles from "./VolumeMeter.module.css";
import { Overlay, Tooltip } from "react-bootstrap";

interface VolumeMeterProps {
  amplitude: number;
  muted: boolean;
  onMute: (muted: boolean) => void;
}

const VolumeMeter: React.FC<VolumeMeterProps> = ({ amplitude, muted, onMute }) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [hoverTimeout, setHoverTimeout] = useState<number | null>(null);
  const target = useRef(null);

  const handleMouseEnter = () => {
    const timeout = window.setTimeout(() => {
      setShowTooltip(true);
    }, 300) as unknown as number; // cast to number
    setHoverTimeout(timeout);
  };

  const handleMouseLeave = () => {
    if (hoverTimeout) {
      window.clearTimeout(hoverTimeout);
    }
    setShowTooltip(false);
    setHoverTimeout(null);
  };

  // Define the maximum height of the bar (this will be the height in one direction)
  const maxHeight = 90; // Height in pixels for the upward or downward direction
  const minHeight = 1; // Minimum height of the bar to show even when amplitude is very low

  // Non-linear transformation function to boost quieter signals
  const applyNonLinearGain = (amplitude: number) => {
    const factor = 1.9; // Controls the steepness of the curve
    return Math.pow(amplitude, 1 / factor);
  };

  // Calculate the height of the bar based on the amplitude
  const adjustedHeight = Math.max(minHeight, applyNonLinearGain(amplitude) * maxHeight);

  // The total height is double the adjusted height because it grows in both directions
  const totalHeight = adjustedHeight * 2;

  return (<>
    <div
      className={styles.volumeMeterContainer}
      ref={target}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={() => {
        onMute(!muted);
      }}
    >
      {!muted &&<div
        className={styles.volumeMeterBar}
        style={{ height: `${totalHeight}%` }}
      />}
      {muted && <i className={`bi bi-volume-mute ${styles.muteIcon}`}></i>}
    </div>
    <Overlay
      target={target.current}
      show={showTooltip}
      placement="top"
    >
      {(props) => (
        <Tooltip id="tooltip-volumemeter-mute" {...props}>
          Mute
        </Tooltip>
      )}
    </Overlay>
  </>
  );
};

export default VolumeMeter;
